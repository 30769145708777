import React from "react";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";


const SalesForceAutomation = () => {
  const bannerPara =
    "Take your sales operations to the next level with cutting-edge automation solutions from National IT Services. Our Sales Force Automation software streamlines lead management, optimizes workflows, and powers productivity - driving explosive growth. Choose from top platforms like Deskera CRM, Freshworks Sales Suite, Gupshup, WhatsApp Business API, and advanced ChatBots. With powerful integrations, easy-to-use interfaces, and real-time analytics, our sales automation tools connect and empower your team like never before. The future of sales is here - embrace it and watch your revenue skyrocket with National IT Services.";
  const bannerHeading = " Supercharge Your Sales Team with Automation Software";
  const Services = AllServices.filter(service => service.category === "Cloud");
  return (
    <>
      <AllCategoriesBanner
        bannerPara={bannerPara}
        bannerHeading={bannerHeading}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ServicesSingleGrid-main-heading-div">
              <h1>Cloud and data centre services </h1>
              <p>
                Discover a wide range of services tailored to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AllCategoriesSingleGrid Services={Services}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesForceAutomation;
