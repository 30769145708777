import React from "react";
import AllCategoriesBanner from "../../Components/All Categories Components/All Categories Banner/AllCategoriesBanner";
import AllCategoriesSingleGrid from "../../Components/All Categories Components/All Categories Single Grid/AllCategoriesSingleGrid";
import AllServices from "../../Components/All Services Components/All Services Data/AllServicesData";

const VoiceDataSolutions = () => {
  const bannerPara =
    "Enable seamless, secure communications across your organization with National IT Services' robust voice and data solutions. Bring your network infrastructure to the next level with high-speed leased lines, reliable PRI/SIP trunking, cloud telephony, VoIP minutes, and more. Our solutions ensure crystal-clear call quality, efficient routing, and scalable capacity to support your growth. With cutting-edge contact center platforms, toll-free services, MPLS, SD-WAN, and expert management, we empower enterprise-grade voice and data. Partner with National IT Services for the technology and support to elevate your capabilities. The future of seamless communication starts here.";
  const bannerHeading = "Power Your Business with Enterprise-Grade Voice & Data";
  const Services = AllServices.filter(service => service.category === "Voice");
  return (
    <>
      <AllCategoriesBanner
        bannerPara={bannerPara}
        bannerHeading={bannerHeading}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ServicesSingleGrid-main-heading-div">
              <h1>Voice & Data Solutions</h1>
              <p>
                Discover a wide range of services tailored to meet your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <AllCategoriesSingleGrid Services={Services}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoiceDataSolutions;
