const popularBrandsData = [
  {
    id: 1,
    title: "Zoom Meetings Pro",
    description:
      "Professional video conferencing with 30-hour meeting duration, Mail and Calendar Client & Service, Clips Plus Unlimited videos, Docs Unlimited, 5GB Cloud Storage, AI Companion, and Essential Apps included. Free premium apps for 1 year.",
    price: "₹ 13200*",
    originalPrice: 16098,
    discount: "Flat 18% Off",
    imageSrc:
      "https://img.freepik.com/premium-vector/zoom-meeting-logo_628407-1899.jpg",
    category: "Collaboration Solutions",
  },

  {
    id: 2,
    title: "Remote access & support",
    description:
      "Mitigate the risk of cyber threats, automate routine IT tasks, and gain better visibility and control over your endpoints with GoTo.",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 15% Off",
    imageSrc:
      "https://www.notebookcheck.net/fileadmin/Notebooks/News/_nc3/gotomeeting_hipaa_compliant_1.jpg",
    category: "Collaboration Solutions",
  },
  {
    id: 3,
    title: "SmartOffice",
    description:
      "SmartOffice®: All-in-One Innovative Single Box Solution Upgrade to an one-stop-solution offering voice, data and apps which is easy to deploy, reliable and affordable.",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 15% Off",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPnYsYz4eUtnbMYHYmThL4DIRrjoCO5aveRw&usqp=CAU",
    category: "Cyber Security Solutions",
  },
  {
    id: 4,
    title: "Freshwork CRM",
    description:
      "Deskera Customer Relationship Management software provides an organization with a single view of its customers, helps automate critical sales processes ",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 10% Off",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcJY9Nmlo8j0vUKXMIARq4sQ21Gd1DXz1E1w&s",
    category: "Sales Force Automation",
  },
  {
    id: 5,
    title: "Toll-Free",
    description:
      "Toll free number in enables callers to connect with a business without paying any charge. An enterprise with a toll free no is globally perceived as being more approachable, credible and well established.",
    price: "Let us Talk",
    originalPrice: "",
    discount: "Flat 10% Off",
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPnYsYz4eUtnbMYHYmThL4DIRrjoCO5aveRw&usqp=CAU",
    category: "Voice & Data Solutions",
  },
];

export default popularBrandsData;
