import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../assets/style/singleservices.css";
import AllServices from "../Components/All Services Components/All Services Data/AllServicesData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import image1 from "../assets/proSingle/expert_Advice_icon.svg";
import image2 from "../assets/proSingle/payment.svg";
import image3 from "../assets/proSingle/price.svg";
import ProductFormPopUp from "../Components/ProductFormPopUp/ProductFormPopUp";

const SingleServices = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  const serviceId = parseInt(id);
  const service = AllServices.find((service) => service.id === serviceId);
  if (!service) {
    return <p>Blog not found</p>;
  }
  const handleGetDemoClick = (product) => {
    setSelectedProduct(product);
  };
  const handleClose = () => {
    setSelectedProduct(null);
  };
  console.log(AllServices.filter(service => service.category === "Cloud"));
  return (
    <>
      <div className="container singleServicesMainOuterDiv">
        <div className="row">
          <div className="col-lg-3">
            <div className="SingleproImageMain">
              <img src={service.imageSrc} alt="singleImage" />
            </div>
          </div>
          <div className="col-lg-9 mainContent_228">
            <div className="breadCrumInner">
              <Link to="/all-services" className="bredcr">
                <h1 className="breadCrumText">All Services</h1>
              </Link>
              <i className="fa-solid fa-chevron-right iconBD"></i>
              <h1 className="breadCrumText">
                <Link
                  to={
                    service.category === "Business"
                      ? "/business-applications"
                      : service.category === "Cyber"
                      ? "/cyber-security-solutions"
                      : service.category === "Collaboration"
                      ? "/collaboration-solutions"
                      : service.category === "Voice"
                      ? "/voice-data-solution"
                      : service.category === "Cloud"
                      ? "/cloud-and-data-centre-solutions"
                      : service.category === "HR"
                      ? "/hr-software"
                      : "/"
                  }
                  className="bredcr"
                >
                  {service.category}
                </Link>
              </h1>
              <i class="fa-solid fa-chevron-right iconBD"></i>
              <h1 className="breadCrumText2">{service.productName}</h1>
            </div>
            <div className="ContentMainDiv">
              <div className="contentInnerDiv">
                <h1>{service.productName}</h1>
                <p>by: {service.productCompany}</p>
              </div>
            </div>
            <div className="ratingDiv">
              <div
                className="ratingInnerDiv"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="stars">
                  {Array.from({ length: service.rating }).map((_, i) => (
                    <i key={i} className="fas fa-star filled"></i>
                  ))}
                  {Array.from({ length: 5 - service.rating }).map((_, i) => (
                    <i key={i} className="fas fa-star"></i>
                  ))}
                </div>
                <div>
                  {service.isNewFeature && (
                    <div className="badge badge-primar232">New Feature</div>
                  )}
                </div>
              </div>
            </div>
            <hr></hr>
            {/* <div className="pricingDiv">
              <div>
                <p className="Startingpricing">Starting Price:</p>
              </div>
              <div>
                <h3 className="priceMainProSig">
                  ₹{service.price} <span className="excl">excl. GST</span>
                  <span className="coloSpanPro">8% off</span>
                </h3>
                <h3 className="cactulaprice">
                  ₹{service.actualPrise}
                  <span className="incl">incl. GST</span>
                </h3>
                <h3 className="mrp">
                  MRP: &nbsp;<del>₹{service.mrp}</del>
                </h3>
              </div>
            </div> */}
            <div className="discriptionMainDiv">
              <p>{service.description}</p>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="someSingleMainBo">
                    <div className="OSmeIMgeDIcMian">
                      <img src={image1} alt="as" />
                    </div>
                    <div className="OSmeIMgeDIcMianCOntner">
                      <h1>Get Instant Expert Advice</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="someSingleMainBo">
                    <div className="OSmeIMgeDIcMian">
                      <img src={image2} alt="as" />
                    </div>
                    <div className="OSmeIMgeDIcMianCOntner">
                      <h1>Instant Demo and Training</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="someSingleMainBo">
                    <div className="OSmeIMgeDIcMian">
                      <img src={image3} alt="as" />
                    </div>
                    <div className="OSmeIMgeDIcMianCOntner">
                      <h1>Assured Best Price Guaranteed</h1>
                    </div>
                  </div>
                </div>
                <div className="DemoMainDiv">
                  <button
                    style={{ width: "100%" }}
                    onClick={() => handleGetDemoClick(service)}
                  >
                    <i className="fa-solid fa-phone"></i>&nbsp;&nbsp;Contact Us
                    Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductFormPopUp product={selectedProduct} onClose={handleClose} />
    </>
  );
};

export default SingleServices;
