const AllServices = [
  {
    id: 1,
    productName: "Zoom Meetings Pro",
    productCompany: "Zoom",
    description:
      "Professional video conferencing with 30-hour meeting duration, Mail and Calendar Client & Service, Clips Plus Unlimited videos, Docs Unlimited, 5GB Cloud Storage, AI Companion, and Essential Apps included. Free premium apps for 1 year.",
    price: 13200,
    actualPrise: 15000,
    mrp: 18000,
    rating: 4.5,
    category: "Collaboration",
    isNewFeature: true,
    imageSrc:
      "https://seeklogo.com/images/Z/zoom-fondo-azul-vertical-logo-8246E36E95-seeklogo.com.png",
  },
  {
    id: 2,
    productName: "SAP B1 and SAP S4 HANA",
    productCompany: "SAP",
    description:
      "Enterprise solution powered by Yotta/NTT, offering Implementation, Training, AMC, Hosting, Cloud, DR services, SAP Ariba, Success Factor, and Module Implementation.",
    price: 50000,
    actualPrise: 60000,
    mrp: 75000,
    rating: 4.8,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZL65DQOJMS3GMp0KtbQbToNDxMkClhwfEAA&s",
  },
  {
    id: 3,
    productName: "GreytHR HRMS",
    productCompany: "GreytHR",
    description:
      "Complete HR solution including Core HR, Payroll, Leave Management, Employee Self Onboarding, Workflow Process Management, Attendance Management, Employee Portal, Geomark+, and Performance Management System.",
    price: 360,
    actualPrise: 400,
    mrp: 500,
    rating: 4.2,
    category: "Business",
    isNewFeature: false,
    imageSrc:
      "https://pbs.twimg.com/profile_images/1758132523354423296/W9G-4Ane_400x400.jpg",
  },
  {
    id: 4,
    productName: "Freshworks CRM",
    productCompany: "Freshworks",
    description:
      "CRM solution for managing Contacts, Accounts, Deals with built-in Email, Chat, Phone. Includes Product Catalogue, Mobile App, Sales Sequence, Account Hierarchy, and Customer reporting.",
    price: 749,
    actualPrise: 900,
    mrp: 1000,
    rating: 4.3,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Freshworks-vector-logo.svg/1200px-Freshworks-vector-logo.svg.png",
  },
  {
    id: 5,
    productName: "Zoho Workplace",
    productCompany: "Zoho",
    description:
      "30GB Mail Box with 100GB Team Drive, multi-domain email hosting, email and folder sharing, Writer/Sheet/Show/Meetings Apps, Zia Gen AI tool, easy data migration from M365 and Google.",
    price: 99,
    actualPrise: 120,
    mrp: 150,
    rating: 4.4,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://i.pinimg.com/736x/36/76/4c/36764cad429d97090de6e08a7ef82c7b.jpg",
  },
  {
    id: 6,
    productName: "DocuSign",
    productCompany: "DocuSign",
    description:
      "E-Signature solution with Intelligent Agreement Management, Contract Lifecycle Management, API integration, and Document Generation capabilities.",
    price: 1000,
    actualPrise: 1200,
    mrp: 1500,
    rating: 4.6,
    category: "Business",
    isNewFeature: false,
    imageSrc:
      "https://storageprdv2inwink.blob.core.windows.net/1a2965b3-2132-40f9-983f-3f92a35743fe/0e8c1e10-6adc-4ffa-a782-4098981d8aa9",
  },
  {
    id: 7,
    productName: "Smart Flow",
    productCompany: "Tata Tele Smartflo",
    description:
      "Comprehensive contact center with unlimited calling, softphone, CRM integration, call recording and analytics, agent features like call barge-in and whispering.",
    price: 500,
    actualPrise: 600,
    mrp: 750,
    rating: 4.1,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQah1Lx2979lH8mJO8BR_mpO7xjglbcQntYzQ&s",
  },
  {
    id: 8,
    productName: "Meeting Room System ",
    productCompany: "Multiple Vendors :- Jabra/ Cisco/ Poly/ Yealink",
    description:
      "Professional meeting room setups from Jabra, Cisco, Poly, Yealink. Personal to large room configurations, Teams/Zoom/Webex certified devices, 180-degree Meeting Bar, Beam forming mic.",
    price: 25000,
    actualPrise: 30000,
    mrp: 35000,
    rating: 4.7,
    category: "Collaboration",
    isNewFeature: true,
    imageSrc:
      "https://www.dsppatech.com/uploads/image/20180628/17/necessity-of-a-meeting-room-speaker-system.jpg",
  },

  // Category: Network Services
  {
    id: 9,
    productName: "Internet Leased Line",
    productCompany: "Multiple Providers :- Tata/ Ishan/ NTT",
    description:
      "High-speed dedicated internet from Tata/Ishan/NTT with Smart Internet features, managed and unmanaged services, special ISP pricing.",
    price: 10000,
    actualPrise: 12000,
    mrp: 15000,
    rating: 4.6,
    category: "Voice",
    isNewFeature: false,
    imageSrc:
      "https://4.imimg.com/data4/FB/EJ/GLADMIN-24669842/1-500x500.png",
  },

  {
    id: 10,
    productName: "Smart VPN",
    productCompany: "Tata",
    description:
      "Tata Smart VPN with managed router and support, domestic and global connectivity options, featuring class of service.",
    price: 20000,
    actualPrise: 25000,
    mrp: 30000,
    rating: 4.4,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQah1Lx2979lH8mJO8BR_mpO7xjglbcQntYzQ&s",
  },
  {
    id: 11,
    productName: "Managed WiFi",
    productCompany: "Multiple Vendors :- Engenious/ Cisco/ Aruba",
    description:
      "Indoor WiFi services using Engenious/Cisco/Aruba, WiFi 7, hosted and on-prem controller, OTP-based guest access, internet bundled solution.",
    price: 15000,
    actualPrise: 18000,
    mrp: 22000,
    rating: 4.5,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://www.dojonetworks.com/hubfs/Videos/Dojo_wifi_02.mp4/medium.jpg",
  },
  {
    id: 12,
    productName: "Network Monitoring & Management",
    productCompany: "Motadata",
    description:
      "Comprehensive IT monitoring solution with Network Observability, Infrastructure Monitoring, Log Analytics, Network Automation, Service desk, and Asset Manager.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.3,
    category: "Voice",
    isNewFeature: false,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQamuxFVPXgI2plGfnlsaV5y8TWK7arT4QuBg&s",
  },
  {
    id: 13,
    productName: "SDWAN and SASE",
    productCompany: "Multiple Vendors :- Versa/ Fortinet/ Cisco/ Silverpeak",
    description:
      "Solutions from Versa/Fortinet/Cisco/Silverpeak with Basic/ATP/UTM options, managed services, secure access, monitoring dashboard, Capex/Opex options.",
    price: 25000,
    actualPrise: 30000,
    mrp: 35000,
    rating: 4.7,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://firebearstudio.com/blog/wp-content/uploads/2017/08/chrome_2018-09-11_19-16-22.png",
  },
  {
    id: 14,
    productName: "Content Delivery Network CDN",
    productCompany: "Multiple Providers :- Akamai/ Cloud Flaire",
    description:
      "CDN solutions from Akamai/Cloud Flare for secure content distribution, live streaming, OTT, website acceleration. AWS/Azure/Bitgravity options available.",
    price: 40000,
    actualPrise: 45000,
    mrp: 50000,
    rating: 4.8,
    category: "Voice",
    isNewFeature: true,
    imageSrc:
      "https://theincmagazine.com/wp-content/uploads/2023/04/CONTENT-DELIVERY-NETWORK-Exploring-the-Power-01-1.jpg",
  },
  {
    id: 15,
    productName: "Network/Firewall Devices",
    productCompany: "Multiple Vendors :- Cisco/ Juniper/ Checkpoint",
    description:
      "Network and security devices from Cisco/Juniper/Checkpoint/Fortinet/HP Aruba, including device licenses, installation, and monitoring.",
    price: 35000,
    actualPrise: 40000,
    mrp: 45000,
    rating: 4.6,
    category: "Voice",
    isNewFeature: false,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY9FjKr_87LyXXWWZ-LJj3JAKJjzmdXBvtZQ&s",
  },

  // Category: Cyber Security
  {
    id: 17,
    productName: "Data Leakage Prevention :- DLP",
    productCompany: "Safetica, Cososys, Trellix, Escan",
    description:
      "Comprehensive data protection solution with profile-wise sharing rights, data templates, multi-OS support, on-prem/SaaS options, and endpoint/mail DLP.",
    price: 12000,
    actualPrise: 14000,
    mrp: 16000,
    rating: 4.5,
    category: "Cyber",
    isNewFeature: false,
    imageSrc:
      "https://cdn.prod.website-files.com/5ff66329429d880392f6cba2/60757c372a2abacb1d83d9aa_what%20is%20dlp.jpeg",
  },
  {
    id: 18,
    productName: "VAPT/GAP Assessment",
    productCompany: "Security Services",
    description:
      "Grey/Black Box Testing, hardware/software assessment, optional source code testing, IP-based device/website/application testing, with mitigation support.",
    price: 20000,
    actualPrise: 25000,
    mrp: 30000,
    rating: 4.4,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxHMHkfEUi9Gw9msQvCzqTvEtpWjQDWIuihA&s",
  },
  {
    id: 19,
    productName: "SIEM/SOAR",
    productCompany: "Security Operations",
    description:
      "24x7 SOC support with on-prem/cloud log collector, compliance fulfillment, global SOC center, L1/L2/L3 support levels.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.8,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://cdn.prod.website-files.com/62d5d3d58a8916f99109ac72/664cfc1041a944ab83fe1b19_Pillr-by-OpenText-Logo-Normal-OTBLUEColor_360px%5B94%5D.png",
  },
  {
    id: 20,
    productName: "MDM",
    productCompany: "GoTo Miradore, Jamf, Scalefusion, eScan",
    description:
      "Multi-OS device management with application control, scripting, asset tracking, service tickets, remote support, and patch management. Options: GoTo Resolve/Jamf/Scalefusion.",
    price: 18000,
    actualPrice: 22000,
    mrp: 25000,
    rating: 4.3,
    category: "Cyber",
    isNewFeature: false,
    imageSrc: "https://logowik.com/content/uploads/images/goto-new6911.jpg",
  },
  {
    id: 21,
    productName: "ITSM",
    productCompany: "GoTo, Motadata, Freshdesk",
    description:
      "Multi-OS device management with application control, scripting, asset tracking, service tickets, remote support, and patch management. Options: GoTo Resolve/Jamf/Scalefusion.",
    price: 18000,
    actualPrice: 22000,
    mrp: 25000,
    rating: 4.3,
    category: "Cyber",
    isNewFeature: false,
    imageSrc: "https://logowik.com/content/uploads/images/goto-new6911.jpg",
  },
  {
    id: 22,
    productName: "Endpoint Security with XDR",
    productCompany: "Multiple Vendors",
    description:
      "Comprehensive endpoint security solutions from Trendmicro, Kaspersky, E-Set, Escan, Trellix with Email Security add-on option.",
    price: 16000,
    actualPrise: 19000,
    mrp: 22000,
    rating: 4.6,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1l-_Qpw3024trqVl_m-5PrzohpPAstRJb6w&s",
  },
  {
    id: 23,
    productName: "Goto Meeting/Webinar",
    productCompany: "Goto",
    description:
      "Online platform for video conferencing and webinars with tools for business expansion, automatic invitations, follow-up emails, and lead generation tactics. Trusted by 50K users globally.",
    price: 200,
    actualPrise: 300,
    mrp: 500,
    rating: 4,
    category: "Collaboration",
    isNewFeature: true,
    imageSrc:
     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY01eQjk76KdcAelhk5sYoXabc8YvlIhGpoA&s"  },
  {
    id: 24,
    productName: "TeamViewer",
    productCompany: "TeamViewer",
    description:
      "Remote access software for desktop sharing and file transfer. Secure screen sharing, file sharing, unattended access, and VPN alternative. Supports Windows, Mac, iOS, and Android.",
    price: 200,
    actualPrise: 300,
    mrp: 500,
    rating: 4,
    category: "Collaboration",
    isNewFeature: true,
    imageSrc:
      "https://play-lh.googleusercontent.com/kiuamHjSKvVwS2fZ9c3dgPBPLDNQhExfw_8_vWhTdFMIQp1Lnf5gl5ZPbO2R55BzBk4",
  },
  {
    id: 25,
    productName: "Google Workspace",
    productCompany: "Google",
    description:
      "Cloud-based software suite offering collaboration tools like Gmail, Docs, Sheets, Forms, Drive, and more. Includes centralized admin console and endpoint management for enterprises.",
    price: 200,
    actualPrise: 300,
    mrp: 500,
    rating: 4.6,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://www.techbrein.com/data/2023/04/google-workspace-banner-2.png",
  },
  {
    id: 26,
    productName: "Colocation Services",
    productCompany: "NTT Global, Yotta, STT",
    description:
      "Reliable colocation services with global connectivity. Ensure high availability, scalability, and security for your IT infrastructure.",
    price: 50000,
    actualPrise: 60000,
    mrp: 75000,
    rating: 4.7,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.datafoundry.com/wp-content/uploads/2024/02/df_fortune_5_colocation-1.png",
  },
  {
    id: 27,
    productName: "Cloud Services",
    productCompany:
      "AWS, Azure, Tata IZO Cloud, NTT Simpli Cloud, Shakti Cloud",
    description:
      "Comprehensive cloud solutions for businesses. Scalable computing power, storage, and advanced cloud features to meet enterprise needs.",
    price: 10000,
    actualPrise: 12000,
    mrp: 15000,
    rating: 4.8,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.device42.com/blog/wp-content/uploads/2023/03/14_01edge-cloud-computing-900x600.jpg",
  },
  {
    id: 28,
    productName: "GPU as a Service",
    productCompany: "AI & ML Applications",
    description:
      "High-performance GPU computing for AI and ML applications. Accelerate workloads with scalable GPU cloud solutions.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.6,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://yotta.com/wp-content/uploads/2023/09/gpu-as-a-service-revolution-transforming-hpc-and-virtual-pro-workstations-1.jpg",
  },
  {
    id: 29,
    productName: "Data Backup",
    productCompany: "Druva, Carbonite, Yotta Safe",
    description:
      "Secure and scalable data backup solutions to protect your critical data. Cloud-based, automated backups with quick recovery options.",
    price: 5000,
    actualPrise: 6000,
    mrp: 7500,
    rating: 4.7,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.advanced-pcs.com/wp-content/uploads/2023/04/Data-Backup2-scaled.jpeg",
  },
  {
    id: 30,
    productName: "VDI/Desktop as a Service",
    productCompany: "Accops",
    description:
      "Virtual Desktop Infrastructure (VDI) solutions for remote and hybrid workforces. Secure, high-performance desktop as a service.",
    price: 15000,
    actualPrise: 18000,
    mrp: 22000,
    rating: 4.5,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.ironorbit.com/wp-content/uploads/2024/06/AdobeStock_612227503-scaled.jpeg",
  },
  {
    id: 31,
    productName: "Cloud Storage",
    productCompany: "Zoho WorkDrive, AWS",
    description:
      "Flexible and secure cloud storage solutions for personal and enterprise use. Store, share, and collaborate on files with ease.",
    price: 500,
    actualPrise: 600,
    mrp: 800,
    rating: 4.4,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://www.hkcert.org/f/guideline/218189/1200c630/hkcert-Cloud%20Storage%20Security%20banner-1860x1046.jpg",
  },
  {
    id: 32,
    productName: "Chatbot",
    productCompany: "Freshchat",
    description:
      "AI-powered chatbot to enhance customer engagement and streamline support. Real-time responses with natural language processing.",
    price: 1200,
    actualPrise: 1500,
    mrp: 1800,
    rating: 4.5,
    category: "Cloud",
    isNewFeature: true,
    imageSrc:
      "https://img.freepik.com/free-vector/chatbot-chat-message-vectorart_78370-4104.jpg",
  },
  {
    id: 33,
    productName: "Bulk Email",
    productCompany: "Zepto Mail, Netcore",
    description:
      "Efficient bulk email solutions for marketing and transactional communication. Scalable, secure, and high-performance email delivery.",
    price: 2000,
    actualPrise: 2500,
    mrp: 3000,
    rating: 4.5,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://www.wpoven.com/blog/wp-content/uploads/2022/09/bulk_mail_service.png",
  },
  {
    id: 34,
    productName: "Email Backup/Archiving",
    productCompany: "MailStore, Netcore",
    description:
      "Comprehensive email backup and archiving solutions to ensure data retention and compliance. Features advanced search and secure storage.",
    price: 3000,
    actualPrise: 3500,
    mrp: 4000,
    rating: 4.6,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://jatheon.com/wp-content/uploads/2021/06/The-Difference-Between-Email-Archiving-and-Email-Backup-Blog.png",
  },
  {
    id: 35,
    productName: "Remote Support App",
    productCompany: "TeamViewer, Goto Resolve",
    description:
      "Remote support applications for secure desktop sharing, file transfer, and troubleshooting. Features include unattended access and cross-platform support.",
    price: 5000,
    actualPrise: 6000,
    mrp: 7500,
    rating: 4.4,
    category: "Business",
    isNewFeature: true,
    imageSrc:
      "https://ossisto.com/wp-content/uploads/2024/01/Types-of-Remote-IT-Support-1.webp",
  },
  {
    id: 36,
    productName: "Asset Management",
    productCompany: "Freshdesk, Goto Resolve",
    description:
      "Comprehensive asset management solutions for tracking, managing, and optimizing IT assets. Features include lifecycle tracking and reporting.",
    price: 10000,
    actualPrise: 12000,
    mrp: 15000,
    rating: 4.6,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://happay.com/blog/wp-content/uploads/sites/12/2023/04/best-asset-management-software-system-solutions.webp",
  },
  {
    id: 37,
    productName: "PAM Solutions",
    productCompany: "BeyondTrust, Okta",
    description:
      "Privileged Access Management (PAM) solutions for secure and compliant management of privileged accounts. Includes multi-factor authentication and session monitoring.",
    price: 20000,
    actualPrise: 25000,
    mrp: 30000,
    rating: 4.7,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://www.cit-net.com/wp-content/uploads/2023/05/Blog-TL-01-1-1030x545.png",
  },
  {
    id: 38,
    productName: "EDRM",
    productCompany: "SECLORE",
    description:
      "Enterprise Digital Rights Management (EDRM) solution for securing sensitive data. Features include policy-driven access controls and tracking.",
    price: 15000,
    actualPrise: 18000,
    mrp: 22000,
    rating: 4.5,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://www.stellexgroup.com/uploads/1/3/2/8/132840864/screen-shot-2021-01-19-at-6-11-46-am_orig.png",
  },
  {
    id: 39,
    productName: "SASE Solution",
    productCompany: "Zscaler, Fortinet, Versa, Checkpoint, Netskope",
    description:
      "Secure Access Service Edge (SASE) solutions for unified cloud-based security and networking. Features include zero-trust access and threat protection.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.8,
    category: "Cyber",
    isNewFeature: true,
    imageSrc:
      "https://embed-ssl.wistia.com/deliveries/3e9387b8f03c92bd9d2a26f774e3e810.webp?image_crop_resized=1280x720",
  },
  {
    id: 40,
    productName: "Checkpoint Security",
    productCompany: "Checkpoint",
    description:
      "Comprehensive cyber security solutions offering firewall, intrusion prevention, endpoint protection, and cloud security.",
    price: 25000,
    actualPrise: 30000,
    mrp: 35000,
    rating: 4.7,
    category: "Cyber",
    isNewFeature: true,
    imageSrc: "https://cdn.crn.in/wp-content/uploads/2024/07/05103704/New-Project-1.jpg",
  },
  {
    id: 41,
    productName: "Fortinet Cyber Protection",
    productCompany: "Fortinet",
    description:
      "Advanced cybersecurity solutions with endpoint security, next-gen firewall, secure SD-WAN, and threat intelligence.",
    price: 30000,
    actualPrise: 35000,
    mrp: 40000,
    rating: 4.8,
    category: "Cyber",
    isNewFeature: true,
    imageSrc: "https://www.telecomreview.com/images/stories/2023/12/Fortinet_Enhances_Operational_Technology_Security.jpg",
  }
];

export default AllServices;
